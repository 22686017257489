import React, { Component } from 'react';
import './ResultPanel.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus, faG } from '@fortawesome/free-solid-svg-icons';

class ResultPanel extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            hotelImages: [],
            hotelImagesLoaded: false,
            panelChanged: false,
            mainImgRotation: 0
        }        

    }

    mapImages(imgList) {

        return imgList.map((elem, i) => {

            let activeFlag = '';
            if (this.props.inReport(elem.id)) {
                activeFlag = 'active'
            }

            let selectButtonIcon = (
                <FontAwesomeIcon icon={faFileCirclePlus}
                    className={`more-images-elem-report-add ${activeFlag}`} 
                    id={`more-images-elem-report-add-${elem.id}`}
                    onClick={(e) => {
                        e.currentTarget.classList.toggle('active');
                        let possibleTarget = document.getElementById(`select-${elem.id}`);
    
                        if (possibleTarget) {
                            possibleTarget.classList.toggle('active');
                        }
    
                        this.props.addReportResult({
                            'id': elem.id,
                            'name': this.props.hotelName,
                            'hotel_id': this.props.hotelID,
                            'path': elem.path,
                            'room': elem.room
                        });
                    }} />
            )

            return (
                // <td className="more-images-elem-wrapper" key={i}>
                // </td>);
                <div className="hotel-img-grid-item">
                    <div className = "more-images-elem-inner-wrapper">
                        {selectButtonIcon}
                        <img className="more-images-elem" alt="more img entry"
                            id={`more-images-elem-${elem.id}`}
                            src={elem.path}
                            onError={(e) => {
                                let elemSelector = document.getElementById(`more-images-elem-${elem.id}`);
                                let errorTextSelector = document.getElementById(`more-images-error-${elem.id}`);
                                elemSelector.classList.toggle('na');
                                errorTextSelector.classList.toggle('na');
                            }}
                        />
                        <div className="more-images-error" id={`more-images-error-${elem.id}`}>
                            Image Not Available
                        </div>
                    </div>
                </div>);

                
        });
    }

    async getHotelImages() {
        let encodedHotelID = encodeURI(this.props.hotelID);
        const rawResponse = await fetch(`/api/hotel_images/${encodedHotelID}`, {
            method: 'GET',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const content = await rawResponse.json();

        let results = content.images;

        // remove our image from more images to avoid redundancy
        for (let i = 0; i < results.length; i++) {
            if (results[i].id === this.props.id) {
                results.splice(i, 1);
            }
        }


        this.setState({ hotelImages: results, hotelImagesLoaded: true });

        return;
    }

    async flagPanelImage(imageID) {
        const rawResponse = await fetch(`/api/report_image/${imageID}`, {
            method: 'GET',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const content = await rawResponse.json();
    }

    render() {
        let panelSelector = document.getElementById(`${this.props.id}-panel`);

        let panelShowing = false;
        if (panelSelector) {
            let panelClassList = [...panelSelector.classList];
            panelShowing = panelClassList.includes('show');
        }
        if ((this.props.active || panelShowing) && !(this.state.hotelImagesLoaded)) {
            this.getHotelImages();
        }

        // Arrow Key listener for modal navigation
        // this could probably be one line of code if you feel like a lil challenge...
        if(panelShowing){
            let next = document.getElementById(`${this.props.id}-right`);
            let prev = document.getElementById(`${this.props.id}-left`);
            document.onkeydown = function() {
                switch (window.event.keyCode) {
                    //this.props is unnaccessible in this context 
                    case 39:
                        //right key
                        console.log("right key");
                        next.click();
                        break;

                    case 37:
                        //left key
                        console.log("left key");
                        prev.click();
                        break;
                };
            };
        };
                    
        // Buttons for modal navigation
        let rightButton = (
            <button className="panel-switch right" id={ `${this.props.id}-right` } onClick={() => { this.props.showNextResult() }}>
                <FontAwesomeIcon icon="arrow-right" />
            </button>);

        let leftButton = (
            <button className="panel-switch left" id={ `${this.props.id}-left` } onClick={() => { this.props.showPreviousResult() }}>
                <FontAwesomeIcon icon="arrow-left" />
            </button>);

        if (!(this.props.prevID)) {
            leftButton = '';
        } else if (!(this.props.nextID)) {
            rightButton = '';
        }

        let hotelImages = (
            <div className="load-hotel-images-parent">
                <FontAwesomeIcon icon="circle-notch"
                    className="fa-spin load-hotel-images" />
                Loading Hotel Images
            </div>
        );

        if (this.state.hotelImagesLoaded) {
            let images = this.state.hotelImages;
            let halfpoint = Math.ceil(images.length / 2)

            hotelImages = (
                <div className="hotel-img-grid-container">
                    {this.mapImages(images)}
                </div>
            )
        }


        let mainImage = (
            <img src={this.props.imgUrl} className="panel-main-image"
                id={`panel-main-image-${this.props.id}`}
                alt={this.props.hotelName}
                onError={() => this.panelImageError(this.props.id)}
            />
        );

        let wrapperClass = 'panel-main-image-wrapper';

        if (this.props.imgUrl.startsWith(window.hostedImagesUrl)) {
            mainImage = (
                <img src={this.props.imgUrl} className="panel-main-image hosted"
                    id={`panel-main-image-${this.props.id}`}
                    alt={this.props.hotelName}
                    onError={() => this.panelImageError(this.props.id)}
                />
            );

            wrapperClass = 'panel-main-image-wrapper hosted';

        }

        let googlemapspage = ""
        if (this.props.google_places_id) {
            googlemapspage = (
                <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.hotelName.replace(/\s/g, "+").replace(/,/g, "%2C")}&query_place_id=${this.props.google_places_id}`}
                    className="panel-hotel-link"
                    target="_blank"
                    rel="noopener noreferrer">
                    <svg aria-hidden="true" focusable="false" class="svg-inline--fa btn-info-label" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 1 20 20">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                    Hotel Page
                </a>
            )
        }

        return (
            <div className="result-panel" id={`${this.props.id}-panel`}
                value="test"
                onKeyDown={() => {
                    if (!(this.state.panelChanged)) {
                        this.setState({ panelChanged: true });
                    }
                    
                }}>
                <span className="exit-panel"
                    onClick={() => { this.props.closeModal() }}
                >
                    X
                </span>
                <div className="panel-left">
                    {leftButton}
                    <div className={wrapperClass} id={`panel-image-wrapper-${this.props.id}`}>
                        {mainImage}
                        <div className="panel-error-text"
                            id={`panel-error-text-${this.props.id}`}
                        >
                            Image Not Available
                        </div>
                    </div>
                    <div className="flag-panel-image" id={`flag-panel-image-${this.props.id}`}
                        onClick={() => {
                            let d = window.confirm('Reporting this image will remove it from future search results, are you sure you wish to proceed?');

                            if (d) {
                                this.flagPanelImage(this.props.id);
                                window.alert('The image has been reported and will be omitted from further search results.');
                            }

                        }}
                    >
                        <FontAwesomeIcon icon="flag" className="flag-panel-image-icon" />
                        Report image
                        </div>
                </div><div className="panel-split"></div><div className="panel-right">
                    <div className="rps-wrapper">
                        {rightButton}
                    </div>
                    <div className="panel-heading-wrapper">
                        <div className="panel-heading">
                            <h1 className="panel-hotel-name"
                                title={this.props.hotelName}
                            >
                                {this.props.hotelName}
                            </h1>
                            <div className="panel-options-container">
                                <div className="panel-score unselectable">
                                    <span className="panel-toggle-breakdown"
                                        onClick={() => {
                                            document.getElementById(`breakdown-${this.props.id}`).classList.toggle('display');
                                            document.getElementById(`panel-toggle-breakdown-icon-${this.props.id}`).classList.toggle('toggled');
                                        }}>
                                        
                                        {
                                            this.props.score ?
                                                `${Math.round(this.props.score * 100)}% match` :
                                                'N/A'
                                        }    

                                        <FontAwesomeIcon icon="chevron-down"
                                            id={`panel-toggle-breakdown-icon-${this.props.id}`}
                                            className="panel-toggle-breakdown-icon" />
                                    </span>
                                    { this.props.scoreBreakdown &&
                                        <div id={`breakdown-${this.props.id}`} className="breakdown">
                                            {this.props.scoreBreakdown.image &&
                                                <span>
                                                    Image
                                                    <span className="breakdown-score">
                                                        {
                                                            `${Math.round(this.props.scoreBreakdown.image * 100, 2)}%`
                                                        }
                                                    </span>
                                                </span>
                                            }
                                            {this.props.scoreBreakdown.text &&
                                                <span>
                                                    Keywords
                                                    <span className="breakdown-score">
                                                        {
                                                            `${Math.round(this.props.scoreBreakdown.text * 100, 2)}%`
                                                        }
                                                    </span>
                                                </span>
                                            }
                                            {this.props.scoreBreakdown.distance &&
                                                <span>
                                                    Location
                                                    <span className="breakdown-score">
                                                        {           
                                                            `${Math.round(this.props.scoreBreakdown.distance * 100, 2)}%`
                                                        }
                                                    </span>
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="panel-options-btns">
                                    {googlemapspage}
                                    <a href={`/hotel/${this.props.hotelID}`}
                                        className="panel-hotel-link"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FontAwesomeIcon icon="globe-americas"
                                            className="btn-info-label" />
                                        Hotel Images
                                    </a>
                                    <a href={this.props.imgUrl}
                                        className="panel-hotel-link"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FontAwesomeIcon icon="expand"
                                            className="btn-info-label" />
                                        View Full Image
                                    </a>
                                </div>    
                            </div>
                            <br />
                            <div className="italics">More Images from hotel:</div>

                        </div>
                    </div>
                    <div className="panel-more">
                        {hotelImages}
                    </div>
                </div>
            </div>
        );
    }

    panelImageError(elem_id) {
        document.getElementById(`panel-main-image-${this.props.id}`).classList.toggle('na');
        document.getElementById(`panel-error-text-${this.props.id}`).classList.toggle('na');

    }

    rotatePanelImage() {
        console.log('h')
        let newRotation = this.state.mainImgRotation === 270 ? 0 : this.state.mainImgRotation + 90;



        let wrapperSelector = document.getElementById(`panel-image-wrapper-${this.props.id}`);
        let maxHeight = wrapperSelector.clientHeight;
        let maxWidth = wrapperSelector.clientWidth;

        // alert(maxHeight);
        // alert(maxWidth);


        let panelImgSelector = document.getElementById(`panel-main-image-${this.props.id}`);
        let height = panelImgSelector.clientHeight;
        let width = panelImgSelector.clientWidth;

        let ogHeight = panelImgSelector.ogHeight ? panelImgSelector.ogHeight : height;
        let ogWidth = panelImgSelector.ogWidth ? panelImgSelector.ogWidth : width;

        panelImgSelector.ogHeight = ogHeight;
        panelImgSelector.ogWidth = ogWidth;




        panelImgSelector.style.transform = `rotate(${newRotation}deg)`;

        if (newRotation === 90 || newRotation === 270) {
            if (width > maxHeight) {
                panelImgSelector.style.width = `${maxHeight}px`;
            }
        } else {
            if (width > maxHeight) {
                panelImgSelector.style.width = `${maxHeight}px`;
            } else {
                panelImgSelector.style.width = `${ogWidth}px`;
            }
        }

        this.setState({ mainImgRotation: newRotation });
    }

}

export default ResultPanel;
