import React, { Component } from 'react';
import './SearchResult.css';
import ResultPanel from '../ResultPanel/ResultPanel.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

class SearchResult extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            panelActive: false
        }
    }

    render() {
        let mainImageClasses = 'result-img';
        if (this.props.imgUrl.startsWith(window.hostedImagesUrl)) {
            mainImageClasses = 'result-img hosted';
        }
        let activeFlag = '';
            if (this.props.inReport(this.props.id)) {
                activeFlag = 'active'
            }

        let selectButtonIcon = (
            <FontAwesomeIcon icon={faFileCirclePlus}
                className={`select ${activeFlag}`} id={`select-${this.props.id}`}
                onClick={(e) => {
                    e.currentTarget.classList.toggle('active');
                    let possibleTarget = document.getElementById(`more-images-elem-report-add-${this.props.id}`);

                    if (possibleTarget) {
                        possibleTarget.classList.toggle('active');
                    }

                    this.props.addReportResult();
                }} />
        )

        return (
            <div className="full-result-wrapper" id={`full-result-wrapper-${this.props.id}`} onClick={this.props.onClick}>
                <div className="result-wrapper" id={`result-wrapper-${this.props.id}`}
                    title={this.props.hotelName}
                    onClick={(e) => {
                        //This handles when path element of svg icon is selected instead of actual svg element
                        if (e.target && !((e.target.className.baseVal) || (e.target.getAttribute("d"))) && !(e.target.className.includes('select'))) {
                            this.props.toggleModal(this.props.id, this.props.hotelID); // Open the modal for this result
                            this.setState({ panelActive: true });
                        }
                    }} >
                    <div className={`placeholder ${this.props.id}`}
                        id={`placeholder-${this.props.id}`}
                        style={{
                            backgroundRepeat: `no-repeat`,
                            backgroundPosition: `center`,
                            backgroundColor: `var(--lighter-grey)`,
                        }}>
                        <img className={mainImageClasses} id={this.props.id + '-result'}
                            alt={this.props.hotelName} onLoad={this.showImage.bind(this)}
                            onError={() => {
                                this.handleImageUnavailable(this.props.id);
                            }}
                            src={this.props.imgUrl} />
                        <div className="result-error-text"
                            id={`result-error-text-${this.props.id}`}
                        >
                            Image Not Available
                        </div>
                        {selectButtonIcon}

                    </div>
                    <div className="result-label">
                        <div className="result-label-inner">
                            {/*<span className="result-label-score">
                                {this.props.score ? Math.round(this.props.score * 100) + '%' : ''}
                            </span>*/}
                            {this.props.hotelName}
                        </div>
                    </div>
                </div>
                <ResultPanel
                    id={this.props.id}
                    hotelName={this.props.hotelName}
                    hotelID={this.props.hotelID}
                    room={this.props.room}
                    imgUrl={this.props.imgUrl}
                    prevID={this.props.prevID}
                    nextID={this.props.nextID}
                    showNextResult={ () => {
                        this.props.toggleModal(this.props.nextID, this.props.nextHotelID); // Open the next modal
                    }}
                    showPreviousResult={ () => {
                        this.props.toggleModal(this.props.prevID, this.props.prevHotelID); // Open the previous modal
                    }}
                    closeModal={ () => {
                        this.props.toggleModal(this.props.id, this.props.hotelID);
                        }}
                    key={this.props.id}
                    active={this.state.panelActive}
                    addReportResult={this.props.panelAddReportResult}
                    inReport={this.props.inReport}
                    score={this.props.score}
                    scoreBreakdown={this.props.scoreBreakdown}
                    google_places_id={this.props.google_places_id}
                />

                <div className="page-shifter" id={`${this.props.id}-shifter`}></div>
            </div>
        );
    }

    showImage() {
        let selector = document.getElementById(this.props.id + '-result');

        if (this.props.imgUrl.startsWith(window.hostedImagesUrl)) {
            var imgHeight = window.getComputedStyle(selector).getPropertyValue('height');
            selector.parentElement.nextSibling.setAttribute('style', `max-width: ${imgHeight}`);
            selector.parentElement.classList.toggle('dynamic-hosted');
            selector.parentElement.setAttribute('style', `width: ${imgHeight}`);
        } else {
            var imgWidth = window.getComputedStyle(selector).getPropertyValue('width');
            selector.parentElement.nextSibling.setAttribute('style', `max-width: ${imgWidth}`);
            selector.parentElement.classList.toggle('dynamic');
        }


        selector.classList.toggle('show');

    }

    handleImageUnavailable(imageID) {
        document.getElementById(`placeholder-${imageID}`).classList.toggle('na');
        document.getElementById(`${imageID}-result`).classList.toggle('na');
        document.getElementById(`result-error-text-${imageID}`).classList.toggle('show');
        return;
    }

}

export default SearchResult;
