import React, { Component } from 'react';
import './ResultsSearchBar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ResultsSearchBar extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.applyFilter = this.applyFilter.bind(this);
    }

    //if we dont debounce the filter call it fires on every keystroke and bogs down the up.
    //the map images method that it leads to is resource intensive.
    debounce(func, wait, immediate) {
        let timeout;
        return function() {
            let context = this, args = arguments;
            let later = function() {
                timeout = null;
                if(!immediate) func.apply(context, args);
            };

            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if(callNow) func.apply(context, args);
        };
    };

    applyFilter = this.debounce(function(event) {
        console.log("Filter Query Recieved: ", event.target.value.trim());
        this.props.applyFilter(event.target.value.trim());
    }, 360);

    render() {
        return (
            <div className="results-search-bar-wrapper">
                <input type="text" placeholder="Filter Results"
                    className="results-search-bar-input"
                    id="results-search-bar-input"
                    onChange={this.applyFilter} />
                <FontAwesomeIcon icon="search" className="results-search-bar-icon" />
            </div>
        );
    }

}

export default ResultsSearchBar;
