import React, {
    Component
} from 'react';
import './AdminPage.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FixedSizeTable from '../../components/FixedSizeTable/FixedSizeTable';
import Checkbox from '../../components/Checkbox/Checkbox';
import Inputbox from '../../components/Inputbox/Inputbox'

import PageBuilder from '../../interfaces/PageBuilder.js';

// Configuration: which text fields can be edited
const editable = ['email', 'name'];

class AdminPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            apiKey: props.initialApiKey,
            apiKeyLoading: false,
            headerList: [...props.headerList],
            userList: [...props.userList],
            // Convert userList to table in constructor to maximize performance
            headerRow: this.buildHeaderRow(props.headerList),
            rows: this.buildBodyRows(props.userList, props.order),
            disableApiKey: props.initialApiKey.length == 0,
        };
    }

    // Update state of API key entry box when the user types
    onApiKeyChange(event) {
        this.setState({
            apiKey: event.target.value,
        });
    }

    // Reset this.state.apiKey to this.props.initialApiKey,
    // undoing any changes made by the user.
    onApiKeyReset() {
        this.setState({
            apiKey: this.props.initialApiKey,
        });
    }

    // Send the API key to the backend, packaging the current API key
    // for one additional level of security.
    async onApiKeySend() {
        this.setState({
            apiKeyLoading: true
        });

        let data = {
            'initialApiKey': this.props.initialApiKey,
            'newApiKey': this.state.apiKey
        };

        const apiKeySendResponse = await fetch('/admin/update_api_key', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).catch((error) => {
            alert(`Unexpected error changing API key:\n${error}`)
        });

        this.setState({
            apiKeyLoading: false
        });

        // On failure, tell the user what went wrong
        if (!apiKeySendResponse.ok) {
            let text = await apiKeySendResponse.text();
            alert(`Error changing API key:\n${text}`);
        } else { // Display a pop-up saying the change was successful
            alert("API key has been changed.");
        }
    }

    buildHeaderRow(headerList) {
        let headerRow = [{'id': 'Delete,0', 'val': <>Delete</>}];
        for (let col = 0; col < headerList.length; col++) {
            let str = `${headerList[col]}`;
            headerRow.push({'id': `${str},${col+1}`, 'val': <>{str}</>});
        }
        return headerRow;
    }

    // Returns a function with the signature (async (checked: bool): bool)
    // that attempts to update the permissions with id `permission` of the
    // user identified by `email` to the value stored in `checked`, return
    // true if the request is successful, and displaying an alert if it is not.
    buildCheckboxOnChange(email, permission) {
        return async (checked) => {
            // Build the body for a request to the backend
            let json = {
                'email': email,
                'permission': permission,
                'value': checked
            };

            // Attempt to change permission
            let request = await fetch('/admin/change_permission', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (request.ok) { // If successful
                alert(`Successfully updated permissions for ${email}`);
                return true;
            } else { // If error
                let text = await request.text();
                alert(`Error changing user permissions:\n${text}`);
                return false;
            }
        };
    }

    // Return a function with the signature (async (string): void)
    // that attempts to update `prop` to `newVal` for the user with
    // email `email`. Displays an alert if the change is not successful.
    buildInputboxOnChange(prop, email) {
        return async(newVal) => {
            // Build the body for a request to the backend
            let json = {
                'email': email,
                'prop': prop,
                'newVal': newVal
            };

            let request = await fetch('/admin/change_property', {
                'method': 'POST',
                'body': JSON.stringify(json),
                'headers': {
                    'Content-Type': 'application/json'
                }
            });

            if (request.ok) { // If successful
                alert(`Successfully updated ${prop} for ${email}`);
            } else { // If error
                let text = await request.text();
                alert(`Error changing user property:\n${text}`);
            }
        };
    }

    // Returns a function with the signature (async (): void)
    // that attempts to delete the user identified by 'email'.
    // If the user is deleted on the backend, the array pointed to by `row`
    // will also be deleted.
    buildDeleteButtonOnClick(email) {
        return async () => {
            const json = {'email': email};
            const request = await fetch('/admin/remove_user', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (request.ok) {
                this.setState((state, props) => {
                    // Locate the row containing `email`
                    const toDel = state.userList.findIndex((element) => {
                        return element['email'] === email;
                    });

                    // Create a copy for splicing
                    let newUserList = [...state.userList];
                    // Rows in `userList` correspond to rows in `rows`,
                    // so we use the same index for editing `rows`
                    let newRows = [...state.rows];

                    newUserList.splice(toDel, 1);
                    newRows.splice(toDel, 1);

                    // Update state
                    return {
                        userList: newUserList,
                        rows: newRows,
                    };
                })
            } else {
                const text = await request.text();
                alert(`Failed to delete user with email ${email}:\n${text}`);
            }
        }
    }

    // userObject -- a single object representing a single object
    // order -- an array of object keys describing the order that user
    // properties should be displayed.
    // Returns: an array of React components
    buildRow(userObject, order) {
        let rowArr = [];

        // email is used as identifier in backend requests
        let email = userObject['email'];

        rowArr.push({
            'id': email,
            'val': <div className='delete-btn-admin'
                            onClick={
                                this.buildDeleteButtonOnClick(email).bind(this)
                            }>
                       <FontAwesomeIcon icon='xmark'/>
                   </div>
        });

        for (const key of order) {
            let entry = userObject[key];

            // 'admin' is unique in that it contains multiple columns
            if (key === 'admin') {
                for (const permission in entry) {
                    let value = entry[permission];

                    let onChange = this.buildCheckboxOnChange(
                        email, parseInt(permission)
                    );

                    let id = `${email},${key},${permission},${value}`;

                    rowArr.push({
                        'id': id, 
                        'val': <Checkbox initCheck={value} 
                                onChange={onChange}/>
                    });
                }
            } else if (editable.includes(key)) {
                let id = `${email},${entry},${key}`;
                let onChange = this.buildInputboxOnChange(key, email);

                rowArr.push({
                    'id': id,
                    'val': <Inputbox initValue={entry}
                            onChange={onChange}/>
                })
            } else {
                let id = `${email},${entry},${key}`;

                rowArr.push({'id': id, 'val': <>{entry}</>})
            }
        }

        return rowArr;
    }

    // userList -- an array of objects representing users; refer to the
    // request_user_list endpoint for examples of a userList.
    // order -- an array of object keys describing the order that user
    // properties should be displayed.
    // Returns: a 2D array containing React components.
    buildBodyRows(userList, order) {
        // Build arrays of components using userList
        let rows = [];
        for (let row = 0; row < userList.length; row++) {
            let rowArr = this.buildRow(userList[row], order);
            rows.push(rowArr);
        }
        return rows;
    }

    // The callback to be executed when the user submits a list of new users
    // to create. Makes a POST request to /admin/add_users, updates
    // this.state.rows, and informs the user what accounts (if any) couldn't
    // be added.

    checkDuplicateEmail(users) {
        // Check if any of the provided emails already exist in the user list
        const existingEmails = new Set(this.state.userList.map(user => user.email));
        const duplicates = users.filter(user => existingEmails.has(user.email));
        if (duplicates.length > 0) {
            alert(`The following emails already exist: ${duplicates.map(user => user.email).join(', ')}`);
            return true; // Return true if duplicates are found
        }
        return false; // No duplicates found
    }

    async onUsersSubmit() {
        // Get a list of name, email pairs from textarea
        const textarea = document.getElementById('add-user-input');
        const value = textarea.value;
        let users = value.split('\n');

        // Split can leave empty strings; remove these empty strings
        users = users.filter((s) => s.length !== 0);

        let error = false;
        let errorString = "";
        users = users.map((s) => {
            let components = s.split(',');
            components = components.map((s) => s.trim());
            // There should only be two components: name and email
            if (components.length !== 2) {
                errorString += `Error parsing user: "${s}".`;
                error = true;
            }
            return {'name': components[0], 'email': components[1]};
        });
        if (error) {
            errorString += `\nMake sure each user is of the form
                            "Name, Email", and that each user is on a
                            separate line.`
            alert(errorString);
            return;
        }

        // Check for duplicate emails
        if (this.checkDuplicateEmail(users)) {
            return;
        }

        // // Ensure that the provided email addresses are valid.
        // // Regex source: https://www.emailregex.com/
        // error = false;
        // errorString = "The following are not valid emails:\n";
        // // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(_.*)?$/;
        // for (const user of users) {
        //     if (!user['email'].match(emailRegex)) {
        //         error = true;
        //         errorString += user['email'] + '\n';
        //     }
        // }
        // if (error) {
        //     alert(errorString);
        //     return;
        // }

        // Make a request to the backend
        // Attempt to change permission
        const json = {
            'newUsers': users
        };
        let request = await fetch('/admin/add_users', {
            method: 'POST',
            body: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (request.ok) { // If successful
            const json = await request.json();
            const newUsers = json['newUsers'];
            let newRows = this.state.rows;

            error = false;
            errorString = 'The following users could not be added: \n';
            for (const user of newUsers) {
                // If there was an error 
                if (user['error'] !== undefined) {
                    error = true;
                    errorString += `- ${user['name']}: ${user['error']}\n`;
                } else {
                    newRows.push(this.buildRow(user, this.props.order))
                }
            }

            if (error) {
                alert(errorString);
            }

            this.setState({ rows: newRows }, () => {
                const scrollable = Array.from(document.querySelectorAll('.admin-table-wrapper'))
                  .find(el => el.scrollHeight > el.clientHeight);
            
                if (scrollable) {
                    scrollable.scrollTo({
                        top: scrollable.scrollHeight,
                        behavior: 'smooth'
                    });
                }

                document.getElementById("add-user-input").value = "";

                alert('Users have been added.'); // Notify the user
            });

            // scroll to the bottom of the list

        } else { // If error
            let text = await request.text();
            alert(`Error adding users: ${text}`);ßß
        }
    }

    render() {
        return (
            <>
                <div className="admin-page-inner">
                    <h1 className='admin-header'>Admin Page</h1>
                    <p>Enter the server's API key below. Changing the API key
                        will restart part of the server; the user should notice
                        no difference.
                    </p>
                    <div className="api-key-row">
                        <input type="text" className="key-input-admin" 
                            disabled={this.state.disableApiKey}
                            value={this.state.apiKey}
                            onChange={this.onApiKeyChange.bind(this)}/>
                        <button className="btn-primary btn-primary-admin"
                            disabled={this.state.disableApiKey}
                            onClick={this.onApiKeySend.bind(this)}>
                            Change API Key
                            {this.state.apiKeyLoading &&
                                <FontAwesomeIcon icon="circle-notch"
                                    className="fa-spin" 
                                    style={{marginLeft: '10px'}} />
                            }
                        </button>
                        <div className="clear-btn-admin"
                            disabled={this.state.disableApiKey}
                            onClick={this.onApiKeyReset.bind(this)}>
                            <FontAwesomeIcon icon="undo-alt" 
                                className="clear-icon-admin"/>
                        </div>
                    </div>
                </div>
                <div className='admin-page-inner'>
                    <h2 className='admin-header'>User List</h2>
                </div>
                <FixedSizeTable headerRow={this.state.headerRow} 
                        tableRows={this.state.rows} height={500}/>
                <div className="admin-page-inner">
                    <h2 className='admin-header'>Add Users</h2>
                    <p>Enter the information for one or more users below in the
                         form "full name, email address". There should be one
                         user per line. Once their email is registered, they
                         can login using their preferred online account, as
                         long as that account was creating using that same 
                         email.
                    </p>
                    <textarea placeholder='First Last, email@domain.com'
                            id='add-user-input'
                            style={{'width': '100%', 'minHeight': '75px'}}/>
                    <br/>
                    <button onClick={this.onUsersSubmit.bind(this)} 
                            className='btn btn-primary'>
                        Submit
                    </button>
                </div>
            </>
        )
    }
}

class AdminPageBuilder extends PageBuilder {

    async getApiKey() {
        // Get the API key from the backend
        let getApiKeyResponse = await fetch('/admin/request_api_key', {
            method: "GET",
            headers: {
                'Accept': 'applicaton/json'
            }
        });

        if (getApiKeyResponse.ok) {
            let json = await getApiKeyResponse.json();
            return json.apiKey;
        } else { 
            // If the user has insufficient permission, or other error occurs
            return ''
        }
    }

    async getUserList() {
        // Get a list of all users who are currently registered with the site
        let getUserResponse = await fetch('/admin/request_user_list', {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        });

        if (getUserResponse.ok) {
            let json = await getUserResponse.json();
            return json;
        } else {
            // If the user has insufficient permissions
            return {};
        }
    }

    // @override
    async onPageLoad() {
        this.initialApiKey = await this.getApiKey();

        let userListJSON = await this.getUserList();
        this.userList = userListJSON.userList;
        this.superUser = userListJSON.superUser;
        this.headerList = userListJSON.headerList;
        this.order = userListJSON.order
    }

    // @override
    pageContent() {
        return (
            <AdminPage initialApiKey={this.initialApiKey} 
                    userList={this.userList}
                    superUser={this.superUser}
                    headerList={this.headerList}
                    order={this.order}/>
        )
    }

}

export {
    AdminPage,
    AdminPageBuilder
};