import React, { Component } from 'react';
import './EditSection.css';

import EditPane from '../../components/EditPane/EditPane.js';

import CanvasDraw from "react-canvas-draw";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBubble from '../../components/InfoBubble/InfoBubble';
import { element } from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';

class EditSection extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            imgUrl: props.imgUrl,
            imgValue: props.imgValue,
            sidebarImgUrl: props.imgUrl,
            maskPaneEnabled: true,
            cropEnabled: false,
            filterPaneEnabled: false,
            editPaneEnabled: false,
            crop: null,
            brushRadius: 30,
            croppedImageUrl: null,
            maskUrl: null,
            brightness: 100,
            contrast: 100,
            saturation: 100,
            croppedUrl: null,
            croppedValue: null,
            previousCropBoxData: null,
            imgRotation: 0,
            brushColor: 'white',
            //~ brushColor: this.getPattern(),
            cropListenersSet: false

        }

        this.minimum_size = 20;

        // Keep stable function references
        // May be unnecessary, but meant to ensure stale listeners can be removed.
        this.startResize = this.startResize.bind(this);
        this.resize = this.resize.bind(this);
        this.stopResize = this.stopResize.bind(this);

        // Store image and crop overlay properties
        this.imageTop = 0;
        this.imageRight = 0;
        this.imageLeft = 0;
        this.imageBottom = 0;
        this.imageHeight = 0;
        this.imageWidth = 0;

        this.cropTop = 0;
        this.cropRight = 0;
        this.cropLeft = 0;
        this.cropBottom = 0;
        this.cropHeight = 0;
        this.cropWidth = 0;

        this.originalMouseX = 0;
        this.originalMouseY = 0;
        this.originalCropWidth = 0;
        this.originalCropHeight = 0;
        this.resizerRef = null;
    }

    componentDidUpdate() {

        if (this.state.filterPaneEnabled) {
            let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];
            imgCanvas.style.filter = `brightness(${this.state.brightness}%)
                                         contrast(${this.state.contrast}%)
                                         saturate(${this.state.saturation}%)`;
        }

    }

    makeResizableDiv() {

        console.log("makeResizableDiv() called! Updating crop variables...");
        const resizers = document.querySelectorAll('.crop-overlay' + ' .crop-over-circle');
        const imgElement = document.getElementsByClassName("canvas-draw")[0].children[0];

        // Capture image properties ONCE per crop session
        const imgRect = imgElement.getBoundingClientRect();
        this.imageTop = imgRect.top;
        this.imageRight = imgRect.right;
        this.imageLeft = imgRect.left;
        this.imageBottom = imgRect.bottom;
        this.imageWidth = imgRect.width;
        this.imageHeight = imgRect.height;

        // Remove any existing event listeners to prevent duplication
        resizers.forEach((resizer) => {
            resizer.removeEventListener("mousedown", this.startResize);
            resizer.addEventListener("mousedown", (e) => this.startResize(e, resizer));
        });

    }

    // Each crop resize button has a mousedown event listener that calls this function
    startResize(e, resizer) {
        
        e.preventDefault()

        const cropOverlay = document.querySelector('.crop-overlay');
        const overlayRect = cropOverlay.getBoundingClientRect();

        //  Capture crop overlay properties at start
        this.originalCropWidth = overlayRect.width;
        this.originalCropHeight = overlayRect.height;
        this.originalMouseX = e.pageX;
        this.originalMouseY = e.pageY;

        //  Track active resizer
        this.resizerRef = resizer;
        // console.log("startResize() initiated for", resizer.classList);

        window.removeEventListener("mousemove", this.resize);
        window.removeEventListener("mouseup", this.stopResize);
        window.addEventListener("mousemove", this.resize);
        window.addEventListener("mouseup", this.stopResize);
    }

    resize(e) {
        if (!this.resizerRef) return;
        
        const cropOverlay = document.querySelector('.crop-overlay');
        const overlayRect = cropOverlay.getBoundingClientRect();

        this.cropTop = overlayRect.top;
        this.cropRight = overlayRect.right;
        this.cropLeft = overlayRect.left;
        this.cropBottom = overlayRect.bottom;
        this.cropHeight = overlayRect.height;
        this.cropWidth = overlayRect.width;

        const hasClass = cls => this.resizerRef.classList.contains(cls);

        if (hasClass('bottom') && hasClass('right')) {
            let width = this.originalCropWidth + (e.pageX - this.originalMouseX);
            let height = this.originalCropHeight + (e.pageY - this.originalMouseY);

            const maxWidth = Math.floor(this.imageWidth - (this.cropLeft - this.imageLeft) - 3);
            const maxHeight = Math.floor(this.imageHeight - (this.cropTop - this.imageTop) - 3);

            width = Math.min(Math.floor(width), maxWidth);
            height = Math.min(Math.floor(height), maxHeight);

            // Ensure minimum size
            width = Math.max(width, this.minimum_size);
            height = Math.max(height, this.minimum_size);

            // Adjust width/height only
            cropOverlay.style.width = `${width}px`;
            cropOverlay.style.height = `${height}px`;
        }
        else if (hasClass('bottom') && hasClass('left')) {
            let height = this.originalCropHeight + (e.pageY - this.originalMouseY);
            let width = this.originalCropWidth - (e.pageX - this.originalMouseX);

            let newLeft = e.pageX - this.imageLeft;
            let newBottom = this.imageBottom - e.pageY;

            // Constrain width/height within image boundaries
            width = width > (this.imageWidth) ? (this.imageWidth) : width;
            height = height > (this.imageHeight) ? (this.imageHeight) : height;

            // Constrain left to image boundaries less minimum size
            if (newLeft >= (this.imageWidth - this.minimum_size)) {
                newLeft = this.imageWidth - this.minimum_size;
            }

            // Ensure minimum size
            width = Math.max(width, this.minimum_size);
            height = Math.max(height, this.minimum_size);

            if (newBottom >= 0) {
                cropOverlay.style.height = height + 'px'
                cropOverlay.style.bottom = newBottom + 'px'
            }

            if (newLeft >= 0) {
                cropOverlay.style.width = width + 'px'
                cropOverlay.style.left = newLeft + 'px'
            }
        }
        else if (hasClass('top') && hasClass('right')) {
            let width = this.originalCropWidth + (e.pageX - this.originalMouseX);
            let height = this.originalCropHeight - (e.pageY - this.originalMouseY);

            let newRight = this.imageRight - e.pageX;
            let newTop = e.pageY - this.imageTop;

            // Constrain width/height within image boundaries
            width = width > (this.imageWidth) ? (this.imageWidth) : width;
            height = height > (this.imageHeight) ? (this.iamge_height) : height;

            // Constrain top to image boundaries less minimum size
            if (newTop >= (this.imageHeight - this.minimum_size)) {
                newTop = this.imageHeight - this.minimum_size;
            }

            // Ensure minimum size
            width = Math.max(width, this.minimum_size);
            height = Math.max(height, this.minimum_size);

            // Update width & right boundary
            if (newRight >= 0) {
                cropOverlay.style.width = width + 'px'
                cropOverlay.style.right = newRight + 'px'
            }
            
            if (newTop >= 0) {
                cropOverlay.style.height = height + 'px';
                cropOverlay.style.top = newTop  + 'px';
            }
        }
        else if (hasClass('top') && hasClass('left')) {
            let width = this.originalCropWidth - (e.pageX - this.originalMouseX);
            let height = this.originalCropHeight - (e.pageY - this.originalMouseY);

            let newLeft = e.pageX - this.imageLeft;
            let newTop = e.pageY - this.imageTop;

            // Constrain width/height within image boundaries
            width = width > (this.imageWidth ) ? (this.imageWidth) : width;
            height = height > (this.imageHeight) ? (this.iamge_height) : height;

            // Constrain top to image boundaries less minimum size
            if (newTop >= (this.imageHeight - this.minimum_size)) {
                newTop = this.imageHeight - this.minimum_size;
            }

            // Constrain left to image boundaries less minimum size
            if (newLeft >= (this.imageWidth - this.minimum_size)) {
                newLeft = this.imageWidth - this.minimum_size;
            }

            // Ensure minimum size
            width = Math.max(width, this.minimum_size);
            height = Math.max(height, this.minimum_size);

            if (newLeft >= 0) {
                cropOverlay.style.width = width + 'px';
                cropOverlay.style.left = newLeft + 'px'
            }

            if (newTop >= 0) {
                cropOverlay.style.height = height + 'px';
                cropOverlay.style.top = newTop + 'px';

            }
        }
    }

    stopResize() {
        console.log("stopResize() called! Removing event listeners...");
        window.removeEventListener("mousemove", this.resize);
        window.removeEventListener("mouseup", this.stopResize);
    }

    // if the user resizes the window, we need to cancel any
    // active crop activity bc it will throw off the crop overlay
    // and the crop dimensions
    handleWindowResize = () => {

        if (!this.state.cropEnabled) {
            return; // Ignore if crop isn't active
        }

        console.log("handleWindowResize() called! Cancelling crop activity...");
        this.stopResize();
        this.toggleCrop("cancel");
        window.removeEventListener('resize', this.handleWindowResize);
    };


    render() {

        if (!(this.state.imgValue)) {
            let newImg = new Image();
            newImg.crossOrigin = undefined;
            newImg.src = this.state.imgUrl;
            newImg.onload = () => {
                this.setState({
                    imgValue: { width: newImg.width, height: newImg.height }
                })
            };

            let interactionPaneClass = this.props.interactionPane ? "interaction-pane" : "";

            if (this.props.overlay) {
                interactionPaneClass += ' overlay';
            }

            let pageContents = (
                <div className={interactionPaneClass}>
                    loading
                </div>);

            return pageContents;
        } else {
            var uploadedImage = this.state.imgValue;
            var imageWidth = uploadedImage.width;
            var imageHeight = uploadedImage.height;

            let imgMaxWidth = 912;
            let imgMaxHeight = 812;
            let widthRatio, heightRatio;

            /* this is triggered whenever we re-draw the image on the
             * canvas. Don't re-size if re-draw is bc of image rotation.
             * It can cause a bug where the image shrinks a little bit on
             * each rotation
             */
            if (this.state.imgRotation === 0){
                // if the image is too wide AND too tall
                if (imageWidth > imgMaxWidth && imageHeight > imgMaxHeight) {
                    // shrink the larger of the two to max dimension size, and scale the other accordingly
                    if (imageWidth > imageHeight) {
                        widthRatio = imageWidth/imgMaxWidth;
                        imageWidth = imgMaxWidth;
                        imageHeight = imageHeight * widthRatio;
                    } else {
                        heightRatio = imgMaxHeight / imageHeight;
                        imageHeight = imgMaxHeight;
                        imageWidth = imageWidth * heightRatio;
                    }
                }

                // if it's too wide
                if (imageWidth > imgMaxWidth) {
                    widthRatio = imgMaxWidth / imageWidth;
                    imageWidth = imgMaxWidth;
                    imageHeight = imageHeight * widthRatio;
                    // if it's too tall
                } else if (imageHeight > imgMaxHeight) {
                    heightRatio = imgMaxHeight / imageHeight;
                    imageHeight = imgMaxHeight;
                    imageWidth = imageWidth * heightRatio;
                }
            }

            this.imageWidth = imageWidth;
            this.imageHeight = imageHeight;

            this.fullImageWidth = uploadedImage.width;
            this.fullImageHeight = uploadedImage.height;


            let displayCrop = this.state.cropEnabled ? 'show' : 'hide';

            let interactionContents = (
                <span id="draw-canvas" >
                    <div className={`crop-overlay ${displayCrop}`}
                        id="crop-overlay"
                        style={{
                            'height': imageHeight,
                            'width': imageWidth
                        }}
                    >
                        <div className="crop-over-circle top left"></div>
                        <div className="crop-over-circle top right"></div>
                        <div className="crop-over-circle bottom left" ></div>
                        <div className="crop-over-circle bottom right"
                            id="crop-circle-bottom-right"></div>

                        <div className="guiding-box-horizontal"></div>
                        <div className="guiding-box-vertical">
                            <div className="crop-center-align unselectable">+</div>
                        </div>
                    </div>
                    <span id="canvas-wrapper">
                        <CanvasDraw
                            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                            lazyRadius={0}
                            brushRadius={this.state.brushRadius}
                            brushColor={this.state.brushColor}
                            catenaryColor={this.state.brushColor}
                            hideGrid={true}
                            imgSrc={this.state.imgUrl}
                            canvasWidth={imageWidth}
                            canvasHeight={imageHeight}
                            disabled={!this.state.maskPaneEnabled}
                            saveData={null}
                            className={`canvas-draw ${this.state.maskPaneEnabled ? "mask-toggled" : ""}`}
                            style={{
                                //~ alternate form of setting img below -- doesn't draw the img 'in' a html canvas
                                //~ backgroundImage: `url(${this.state.imgUrl})`,
                                backgroundSize: `${imageWidth}px ${imageHeight}px`,
                                // this is one way of getting the filters to work
                                // see the end of componentDidUpdate for current method
                                //~ filter: `brightness(${this.state.brightness}%)
                                         //~ contrast(${this.state.contrast}%)
                                         //~ saturate(${this.state.saturation}%)`
                            }}
                        />
                    </span>
                    <canvas id="out-canvas"></canvas>
                    <canvas id="mask-canvas"></canvas>
                </span>
            );

            let formatText = `
                    To proceed with the image search, you will need to mask off any
                    areas of the photograph that show the victim or other sensitive
                    content. Use the eraser tool below to remove any parts of the
                    image that aren’t a part of the hotel room (e.g. the entire
                    victim and any luggage or other items in the room). You may also
                    crop your image and adjust various attributes of the image's
                    style to produce a better image of the hotel.`;

            let interactionPaneClass = this.props.interactionPane ? "interaction-pane" : "";

            if (this.props.overlay) {
                interactionPaneClass += ' overlay';
            }

            let pageContents = (
                <Container fluid className={interactionPaneClass}>
                    <div className="interaction-pane-label">
                        <div id="interaction-pane-label-text">
                            Format Image
                            </div>
                        <InfoBubble direction="right" text={formatText} />
                    </div>
                    <Row style={{ flexWrap: 'nowrap' }}>
                        <Col xs={3} className="edit-pane-col">
                            <EditPane
                                onEditComplete={this.completeEdit.bind(this)}
                                rotateCallback={this.rotateUpload.bind(this)}
                                cropCallback={this.toggleCrop.bind(this)}
                                maskPaneCallback={this.toggleMaskPane.bind(this)}
                                filterPaneCallback={this.toggleFilterPane.bind(this)}
                                editPaneCallback={this.toggleEditPane.bind(this)}
                                undoEdit={this.undoEdit.bind(this)}
                                clearEdit={() => {
                                    this.clearEdit();
                                }}
                                executeSearch={() => {

                                    //let spinner = document.getElementById('loadingsearch');

                                    //spinner.style.display = 'inline-block';
                                    this.executeSearch();
                                }}
                                //dont think this does anything. cant find props.updateSearch
                                updateSearch={this.props.updateSearch}

                                brushSize={this.state.brushRadius}
                                cropEnabled={this.state.cropEnabled}
                                updateBrush={(newVal) => { this.setState({ brushRadius: newVal }) }}

                                updateBrightness={(newVal) => { this.setState({ brightness: newVal }) }}
                                updateContrast={(newVal) => { this.setState({ contrast: newVal }) }}
                                updateSaturation={(newVal) => { this.setState({ saturation: newVal }) }}

                                mask={true} />
                        </Col>
                        <Col xs={9} className="interaction-col">
                            <div id="interaction-upload-wrapper" style={{ transform: `rotate(90deg) !important` }}>
                                {interactionContents}
                            </div>
                        </Col>
                    </Row>
                </Container>);

            return pageContents;
        }
    }

    getPattern() {
        var patternCanvas = document.createElement('canvas'),
            squareWidth = 20,
            patternCtx = patternCanvas.getContext('2d');

        patternCanvas.width = patternCanvas.height = 2 * squareWidth;

        patternCtx.fillStyle = 'LightGray';
        patternCtx.fillRect(0, 0, squareWidth, squareWidth);
        patternCtx.fillRect(squareWidth, squareWidth, squareWidth, squareWidth);
        patternCtx.fillStyle = 'white';
        patternCtx.fillRect(squareWidth, 0, squareWidth, squareWidth);
        patternCtx.fillRect(0, squareWidth, squareWidth, squareWidth);
        //~ patternCtx.fill();
        
        return patternCtx.createPattern(patternCanvas, 'repeat');
    }
    
    drawImage() {
        var canvas = document.getElementById('mainCanvas');
        var ctx = canvas.getContext('2d');

        var img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0); // Or at whatever offset you like
        };
        img.src = this.state.imgUrl;
    }

    undoEdit() {
        this.saveableCanvas.undo();
    }

    clearEdit() {
        this.saveableCanvas.clear();
    }

    rotateImage = async (dataUrl, degrees) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = dataUrl;
    
            img.onload = () => {
                // Swap canvas dimensions for 90-degree rotation
                canvas.width = img.height;
                canvas.height = img.width;
    
                // Rotate the image
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((degrees * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);
    
                // Convert to Data URL and return the result
                canvas.toBlob((blob) => {
                    const imgUrl = URL.createObjectURL(blob);
                    resolve(imgUrl);
                });
            };
        });
    };
    
    rotateUpload = async () => {
        this.setState(
            (prevState) => ({ imgRotation: prevState.imgRotation + 90 }),
            () => {
                // console.log(`rotateUpload: Updated imgRotation: ${this.state.imgRotation}`);
            }
        );
    
        try {
            const rotatedImgUrl = await this.rotateImage(this.state.imgUrl, 90);
            const newImg = new Image();
            newImg.src = rotatedImgUrl;
    
            newImg.onload = () => {
                // Store the new dimensions
                this.setState(
                    {
                        imgUrl: rotatedImgUrl,
                        imgValue: { width: newImg.width, height: newImg.height },
                    },
                    () => {
                        this.clearEdit();
                        this.resetCropOverlay();
                    }
                );
            };
        } catch (error) {
            console.error("rotateUpload: Error rotating image:", error);
        }
    };
    
    resetCropOverlay() {
        const cropper = document.getElementById('crop-overlay');
        if (cropper) {
            cropper.style.left = 0;
            cropper.style.top = 0;
            cropper.style.right = 0;
            cropper.style.bottom = 0;
        }
        this.setState({ cropListenersSet: false });
    }

    toggleCrop(action) {


        let hideOnCrop = document.getElementsByClassName("hide-on-crop");
        let showOnCrop = document.getElementsByClassName("show-on-crop");
        // some buttons are disabled when cropEnabled is true. This is done
        // directly in the button element declaration.
        
        if (action === "start") {
            this.setState({
                cropEnabled: true
            });

            // Only add the resize listener if it's not already set
            if (!this.state.cropListenersSet) {
                window.addEventListener('resize', this.handleWindowResize);
                console.log("cropEnabled is true, setting crop listeners");
                this.makeResizableDiv();
                this.setState({ cropListenersSet: true });
            }

            this.clearEdit();
            
            const cropper = document.getElementById('crop-overlay');
            let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];
            const imgRect = imgCanvas.getBoundingClientRect();

            // Ensure cropper is absolutely positioned relative to its parent
            cropper.style.position = 'absolute';
            cropper.style.left = '0px';
            cropper.style.top = '0px';
            cropper.style.width = `${Math.round(imgRect.width)}px`;
            cropper.style.height = `${Math.round(imgRect.height)}px`;

            // Optional: if the cropper’s parent isn't already relatively positioned
            const parent = imgCanvas.parentElement;
            if (getComputedStyle(parent).position === 'static') {
                parent.style.position = 'relative';
            }
            
            
            Array.prototype.forEach.call(hideOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            Array.prototype.forEach.call(showOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            
        } else if (action === "cancel") {
            this.setState({
                cropEnabled: false,
                cropListenersSet: false
            });

            window.removeEventListener("resize", this.handleWindowResize);

            Array.prototype.forEach.call(hideOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            Array.prototype.forEach.call(showOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });

        } else {

            let imgObj = new Image();
            imgObj.src = this.state.imgUrl;

            imgObj.onload = () => {
                const canvasWrapper = document.getElementById('draw-canvas').getBoundingClientRect();
                const cropper = document.getElementById('crop-overlay').getBoundingClientRect();

                const displayWidth = Math.round(canvasWrapper.width);
                const displayHeight = Math.round(canvasWrapper.height);

                const actualWidth = imgObj.width;
                const actualHeight = imgObj.height;

                const scaleX = actualWidth / displayWidth;
                const scaleY = actualHeight / displayHeight;

                // Use floor for position, ceil/round for size
                const sourceX = Math.floor((cropper.left - canvasWrapper.left) * scaleX);
                const sourceY = Math.floor((cropper.top - canvasWrapper.top) * scaleY);
                const sourceWidth = Math.ceil(cropper.width * scaleX);
                const sourceHeight = Math.ceil(cropper.height * scaleY);

                // Clamp to image bounds to avoid overflow
                const clampedWidth = Math.min(sourceWidth, actualWidth - sourceX);
                const clampedHeight = Math.min(sourceHeight, actualHeight - sourceY);

                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = clampedWidth;
                tempCanvas.height = clampedHeight;
                tempCanvas.style.width = `${clampedWidth}px`;
                tempCanvas.style.height = `${clampedHeight}px`;

                const tempContext = tempCanvas.getContext('2d');

                tempContext.drawImage(
                    imgObj,
                    sourceX, sourceY, clampedWidth, clampedHeight,
                    0, 0, clampedWidth, clampedHeight
                );

                const croppedImageUrl = tempCanvas.toDataURL();

                this.setState({
                    imgUrl: croppedImageUrl,
                    imgValue: { width: clampedWidth, height: clampedHeight },
                    cropEnabled: false,
                    cropListenersSet: false
                });

                window.removeEventListener("resize", this.handleWindowResize);

                // Toggle crop buttons
                Array.prototype.forEach.call(hideOnCrop, item => item.classList.toggle("hide-btn"));
                Array.prototype.forEach.call(showOnCrop, item => item.classList.toggle("hide-btn"));
            };
            
        }
    }

    toggleEditPane() {
        this.setState({
            maskPaneEnabled: false,
            editPaneEnabled: true,
            filterPaneEnabled: false,
            brushColor: '#ffffff00',
        });
    }

    toggleMaskPane() {
        this.setState({
            maskPaneEnabled: true,
            editPaneEnabled: false,
            filterPaneEnabled: false,
            brushColor: 'white',
            //~ brushColor: this.getPattern(),
        });
    }

    toggleFilterPane() {
        this.setState({
            maskPaneEnabled: false,
            editPaneEnabled: false,
            filterPaneEnabled: true,
            brushColor: '#ffffff00',
        });
    }

    completeEdit() {
        let croppedImage = this.refs.cropper.getCroppedCanvas();
        let cropped = this.refs.cropper.getCroppedCanvas().toDataURL();
        this.setState({
            croppedImageUrl: cropped,
            croppedImageHeight: croppedImage.height,
            croppedImageWidth: croppedImage.width
        });
    }

    openDataUrl(url) {
        var iframe = `<iframe width='100%' height='100%' src='${url}'></iframe>`
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
    }

    downloadDataUrl(uri) {
        var link = document.createElement("a");
        link.download = 'data';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    executeSearch() {
        // Retrieve the canvas that is holding the original image
        let img = document.getElementsByClassName("canvas-draw")[0].children[0]
        let imgCanvas = document.getElementById('out-canvas');
        let imgCanvasContext = imgCanvas.getContext('2d');
        imgCanvasContext.canvas.width = img.width;
        imgCanvasContext.canvas.height = img.height;
        // Retrieve the mask only
        let drawing = this.saveableCanvas.canvas.drawing;
        let drawingContext = drawing.getContext('2d');
        
        imgCanvasContext.filter = `brightness(${this.state.brightness}%)
                                         contrast(${this.state.contrast}%)
                                         saturate(${this.state.saturation}%)`;
        // Draw the image onto a canvas, and mask it
        imgCanvasContext.drawImage(img, 0, 0)
        imgCanvasContext.filter = `none`;
        imgCanvasContext.drawImage(drawing, 0, 0);

        let outputUrl = imgCanvasContext.canvas.toDataURL();
        let maskUrl = drawingContext.canvas.toDataURL();

        // set page to show results, and pass output for sidebar image, and full image url
        //this.props.changePage('showResults', outputUrl, outputUrl, this.state.imgValue, maskUrl);
        this.props.handleSearch(outputUrl, outputUrl, this.state.imgValue, maskUrl);
    }
}

export default EditSection;
