import React, { Component } from 'react';
import './EditPane.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-switch';

class EditPane extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            brushSize: 33,
        };
    }

    render() {
        let parentClassName = '';
        if (this.props.searchEdit) {
            parentClassName = 'search-edit';
        }

        let contents = (
            <div className={parentClassName}>
                <div className="basic-edit">
                    <div className="left-buttons">
                        <button className="edit-btn show-children" disabled={this.props.cropEnabled} id="mask-btn"
                            onClick={() => this.handleClick('mask-btn', 'mask-edit', this.props.maskPaneCallback)}>
                            <div className="bold">Mask</div>
                            <FontAwesomeIcon icon="pen" className="mask-icon" />
                            <span className="bottomPad"></span>
                        </button>

                        <button className="edit-btn" id="crop-btn"
                            onClick={() => this.handleClick('crop-btn', 'crop-edit', this.props.editPaneCallback)}>
                            <div className="bold">Edit</div>
                            <FontAwesomeIcon icon="border-none" className="edit-icon" />
                            <span className="bottomPad"></span>
                        </button>
                        <button className="edit-btn" disabled={this.props.cropEnabled} id="filter-btn"
                            onClick={() => this.handleClick('filter-btn', 'filter-edit', this.props.filterPaneCallback)}>
                            <div className="bold">Filter</div>
                            <FontAwesomeIcon icon="sliders-h" className="rotate-icon" />
                            <span className="bottomPad"></span>
                        </button>
                    </div>
                </div>

                <div className="option-pane brush-edit show" id="mask-edit">
                    <div className="brush-edit-btns">
                        <div className="undo-btn" onClick={this.props.undoEdit}>
                            <div className="bold">Undo</div>
                            <FontAwesomeIcon icon="undo-alt"
                                className="changes undo-icon" id="undo" />
                        </div>
                        <div className="clear-btn" onClick={this.props.clearEdit}>
                            <div className="bold">Clear</div>
                            <FontAwesomeIcon icon="times"
                                className="changes clear-icon" id="clear" />
                        </div>
                    </div>
                    <br></br>
                    <span className="ul bold">
                        Eraser Size
                    </span>
                    <br />
                    <input
                        id="brush-rad"
                        type="range"
                        min="5" max="50"
                        defaultValue={this.props.brushSize}
                        onChange={this.handleChange.bind(this)}
                        step="1" />
                </div>

                <div className="option-pane" id="crop-edit">
                    <div class="crop-intro">
                        <span>Use the crop tool on the right to crop your image accordingly. You'll need to finish or cancel cropping before you can continue.</span>
                        <p> ** Using the crop or rotate tool will erase any previous mask drawings </p>
                    </div>
                    <div class= "crop-options-wrapper">
                        <div className="edit-btn hide-on-crop" id="start-crop-btn"
                            onClick={() => { this.props.cropCallback("start"); }}>
                            <div className="bold">Crop</div>
                            <FontAwesomeIcon icon="crop-alt" className="edit-icon" />
                            <span className="bottomPad"></span>
                        </div>
                        <div className="edit-btn hide-on-crop" id="rotate-btn"
                            onClick={() => { this.props.rotateCallback(); }}>
                            <div className="bold">Rotate</div>
                            <FontAwesomeIcon icon="sync-alt" className="rotate-icon" />
                            <span className="bottomPad"></span>
                        </div>
                        <div className="edit-btn show-on-crop hide-btn" id="finish-crop-btn"
                            onClick={() => { this.props.cropCallback("finish"); }}>
                            <div className="bold">Finish</div>
                            <FontAwesomeIcon icon="cut" className="finish-crop-icon" />
                            <span className="bottomPad"></span>
                        </div>
                        <div className="edit-btn show-on-crop hide-btn" id="cancel-crop-btn"
                            onClick={() => { this.props.cropCallback("cancel"); }}>
                            <div className="bold">Cancel</div>
                            <FontAwesomeIcon icon="times" className="cancel-crop-icon" />
                            <span className="bottomPad"></span>
                        </div>
                    </div>
                </div>
                <div className="option-pane" id="filter-edit">
                    <div>
                        <span className="ul bold">
                            Brightness
                        </span>
                        <br />
                        <input
                            id="brightness-val"
                            type="range"
                            min="10" max="220"
                            defaultValue={100}
                            onChange={this.brightnessChange.bind(this)}
                            step="1" />
                    </div>
                    <br />
                    <div>
                        <span className="ul bold">
                            Contrast
                        </span>
                        <br />
                        <input
                            id="contrast-val"
                            type="range"
                            min="10" max="220"
                            defaultValue={100}
                            onChange={this.contrastChange.bind(this)}
                            step="1" />
                    </div>
                    <br />
                    <div>
                        <span className="ul bold">
                            Saturation
                        </span>
                        <br />
                        <input
                            id="saturation-val"
                            type="range"
                            min="10" max="220"
                            defaultValue={100}
                            onChange={this.saturationChange.bind(this)}
                            step="1" />
                    </div>
                    <br />
                    <div>
                        <button
                            className="changes clear-filter-btn bold" id="clear-filter" onClick={this.clearFilters.bind(this)}>
                            Reset Filters
                        </button>
                    </div>
                    <br />
                </div>
            </div>);

        return (
            <div className="edit-pane">
                {contents}

                {
                    this.props.updateSearch ?
                        <button className="btn-alt" id="trueSearch"
                            onClick={() => { this.props.executeSearch() }}>
                            Update Search
                        <span id="loadingsearch">
                                <FontAwesomeIcon icon="circle-notch"
                                    className="fa-spin load-search" />
                            </span>
                        </button>
                        :
                        <button className="btn-primary" id="trueSearch" disabled={this.props.cropEnabled}
                            onClick={() => { this.props.executeSearch() }}>
                            Search
                        <span id="loadingsearch">
                                <FontAwesomeIcon icon="circle-notch"
                                    className="fa-spin load-search" />
                            </span>
                        </button>
                }
            </div >
        );
    }

    toggleAdvanced() {
        document.getElementById('advancedt-chevron').classList.toggle('up');
        document.querySelector('.advanced-edit-tools').classList.toggle('show');
    }

    handleChange() {
        let newVal = document.getElementById('brush-rad').value;
        this.setState({ brushSize: newVal })
        this.props.updateBrush(newVal)
    }

    brightnessChange() {
        let newVal = document.getElementById('brightness-val').value;
        this.props.updateBrightness(newVal);
    }

    contrastChange() {
        let newVal = document.getElementById('contrast-val').value;
        this.props.updateContrast(newVal);
    }

    saturationChange() {
        let newVal = document.getElementById('saturation-val').value;
        this.props.updateSaturation(newVal);
    }

    clearFilters() {
        let defaultFilterValue = 100;
        this.props.updateBrightness(defaultFilterValue);
        this.props.updateContrast(defaultFilterValue);
        this.props.updateSaturation(defaultFilterValue);

        document.getElementById('brightness-val').value = defaultFilterValue;
        document.getElementById('contrast-val').value = defaultFilterValue;
        document.getElementById('saturation-val').value = defaultFilterValue;
    }

    handleClick(id, childID, handler) {
        let ebs = document.getElementsByClassName('edit-btn');
        let ops = document.getElementsByClassName('option-pane');

        Array.prototype.forEach.call(ebs, function (el) {
            el.classList.remove('show-children');
        });

        Array.prototype.forEach.call(ops, function (el) {
            el.classList.remove('show');
        });

        document.getElementById(id).classList.toggle('show-children');
        document.getElementById(childID).classList.toggle('show');
        //~ if (childID === 'crop-edit') {
            //~ document.getElementById('more-edit').classList.toggle('show');
        //~ }

        handler();
    }

}

export default EditPane;
